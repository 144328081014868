import { createContext, createResource, createSignal, onMount, ResourceReturn, sharedConfig, useContext } from 'solid-js';
import { createStore } from 'solid-js/store';
import { isServer } from 'solid-js/web';
import { ButtonColorName, ColorName, FooterColorTheme, HeaderColorTheme, SectionColorTheme } from '../style/color-classes';
import getHashKey from '../tools/get-hash-key';
import { SiteInfo } from '../types/app-state';
import { ImageWordpress } from '../types/shared';

type Viewport = { width: number; height: number };

export type PageThemeType = {
    colors: SectionColorTheme;
    transparentSiteHeader: boolean;
    footerColors: FooterColorTheme;
    headerColors: HeaderColorTheme;
    postType: string;
};

const desktopViewportDefault = { width: 1920, height: 1200 };
const mobileViewportDefault = { width: 480, height: 900 };

type AppContextProviderProps = {
    client: (query: string, variables: any) => any;
    cache: any;
    children?: any;
    imagesServiceUrl: string;
    supportedImageFormats: {
        webp: boolean;
        avif: boolean;
    };
    viewport: Viewport;
    mobile: null | string;
    setStatusCode: (statusCode: number) => void;
    page404url: string;
    pageBlogArchiveUrl: string;
    pageNewsArchiveUrl: string;
    siteLogo?: ImageWordpress;
    siteInfo: SiteInfo;
};

type ContextType = {
    createCachedResource: (graphQlQuery: string, variables?: any, useCache?: boolean) => ResourceReturn<any>;
    graphQlClient: (graphQlQuery: string, variables: any, useCache?: boolean) => Promise<any>;
    imagesServiceUrl: string;
    viewport: Viewport;
    updateViewport: (viewport: Viewport) => void;
    setStatusCode: (statusCode: number) => void;
    setBlogCategories: (categories: string[]) => void;
    setPageTheme: (pageTheme: PageThemeType) => void;
    pageTheme: () => PageThemeType;
    setScrollValue: (scrollValue: number) => void;
    scrollValue: () => number;
    blogCategories: () => string[];
    projectCategories: () => string[];
    setProjectCategories: (categories: string[]) => void;
    supportedImageFormats: {
        webp: boolean;
        avif: boolean;
    };
    page404url: string;
    pageBlogArchiveUrl: string;
    pageNewsArchiveUrl: string;
    siteLogo?: ImageWordpress;
    siteInfo: {
        siteId: string;
        target: string;
        baseurl: string;
    };
};

export const AppContext = createContext<ContextType>({
    createCachedResource: () => createResource(() => true),
    graphQlClient: () => new Promise((resolve) => resolve(1)),
    imagesServiceUrl: '',
    viewport: desktopViewportDefault,
    updateViewport: () => {
        /* placeholder */
    },
    setStatusCode: () => {
        /* placeholder */
    },
    setBlogCategories: () => {
        /* placeholder */
    },
    blogCategories: () => {
        return [];
    },
    setProjectCategories: () => {
        /* placeholder */
    },
    projectCategories: () => {
        return [];
    },
    setPageTheme: () => {
        /* placeholder */
    },
    pageTheme: () => {
        return {
            colors: { classes: '', bgColor: ColorName.noValue, textColor: ColorName.noValue },
            transparentSiteHeader: false,
            footerColors: {
                bgColor: ColorName.noValue,
                textColor: ColorName.noValue,
                headingColor: ColorName.noValue,
                buttonColor: ButtonColorName.White,
                secondaryButtonColor: ButtonColorName.White,
                socialMediaButtonBackgroundColor: ColorName.noValue,
                descriptionColor: ColorName.noValue,
                linkColor: ColorName.noValue,
            },
            headerColors: {
                buttonHoverColor: ColorName.noValue,
                contactBorderColor: ColorName.noValue,
                contactBorderHoverColor: ColorName.noValue,
                linkColor: ColorName.noValue
            },
            postType: '',
        };
    },
    setScrollValue: () => {
        /* placeholder */
    },
    scrollValue: () => {
        return 0;
    },
    supportedImageFormats: {
        webp: false,
        avif: false,
    },
    page404url: '',
    pageBlogArchiveUrl: '',
    pageNewsArchiveUrl: '',
    siteInfo: {
        siteId: '',
        target: '',
        baseurl: 'tjena',
    },

});

export function AppContextProvider(props: AppContextProviderProps) {
    const [cache, setCache] = createStore(props.cache);
    const initialViewport = props.mobile ? mobileViewportDefault : props.viewport;
    const [viewport, setViewport] = createStore(initialViewport);
    const [pageTheme, setPageTheme] = createSignal<PageThemeType>({
        colors: { classes: '', bgColor: '', textColor: '' },
        transparentSiteHeader: false,
        footerColors: {
            bgColor: ColorName.noValue,
            textColor: ColorName.noValue,
            headingColor: ColorName.noValue,
            buttonColor: ButtonColorName.White,
            secondaryButtonColor: ButtonColorName.White,
            socialMediaButtonBackgroundColor: ColorName.noValue,
            linkColor: ColorName.noValue,
            descriptionColor: ColorName.noValue
        },
        headerColors: {
            buttonHoverColor: ColorName.noValue,
            contactBorderColor: ColorName.noValue,
            contactBorderHoverColor: ColorName.noValue,
            linkColor: ColorName.noValue
        },
        postType: '',
    });
    const [scrollValue, setScrollValue] = createSignal(0);

    const [blogCategories, setBlogCategories] = createSignal<string[]>([]);
    const [projectCategories, setProjectCategories] = createSignal<string[]>([]);

    function getInitialValue(key: string, useCache = true): string {
        const root = '0-0-0-0-0-0-0';
        if (!isServer && sharedConfig.context?.id !== root + '-') {
            useCache = true;
        }
        return useCache ? cache[key] : '';
    }

    const graphQlClient = async (graphQlQuery = '', variables = {}, useCache = true): Promise<any> => {
        const key = getHashKey(graphQlQuery, variables);

        if (useCache && cache[key]) {
            return Promise.resolve(cache[key]);
        }

        const response = await props.client(graphQlQuery, variables);

        if (useCache) {
            setCache({ [key]: response });
        }

        return response;
    };

    const createCachedResource = (graphQlQuery = '', variables: any = {}, useCache = true) => {
        const key = getHashKey(graphQlQuery, typeof variables === 'function' ? variables() : variables);
        if (isServer) {
            useCache = true;
        }

        async function fetcher(_: any, { refetching }: { refetching?: unknown }): Promise<any> {
            if (refetching) {
                useCache = false;
            }

            if (typeof variables === 'function') {
                return graphQlClient(graphQlQuery, variables(), useCache);
            }
            return graphQlClient(graphQlQuery, variables, useCache);
        }

        const options: any = {
            initialValue: getInitialValue(key, useCache),
        };

        if (isServer && options.initialValue) {
            options.ssrLoadFrom = 'initial';
        }

        if (typeof variables === 'function') {
            return createResource(variables, fetcher, options);
        }

        return createResource(fetcher, options);
    };

    type Viewport = {
        height: number;
        width: number;
    };
    const updateViewport = (viewport: Viewport) => {
        setViewport(viewport);
    };

    onMount(async () => {
        updateViewport({ width: window.innerWidth, height: window.innerHeight });

        window.addEventListener('resize', () => {
            updateViewport({ width: window.innerWidth, height: window.innerHeight });
        });
    });

    return (
        <AppContext.Provider
            value={{
                createCachedResource,
                graphQlClient,
                imagesServiceUrl: props.imagesServiceUrl,
                viewport: viewport,
                updateViewport,
                supportedImageFormats: props.supportedImageFormats,
                setStatusCode: props.setStatusCode,
                setBlogCategories: setBlogCategories,
                blogCategories: blogCategories,
                setProjectCategories: setProjectCategories,
                projectCategories: projectCategories,
                setPageTheme: setPageTheme,
                pageTheme: pageTheme,
                scrollValue: scrollValue,
                setScrollValue: setScrollValue,
                page404url: props.page404url,
                pageBlogArchiveUrl: props.pageBlogArchiveUrl,
                pageNewsArchiveUrl: props.pageNewsArchiveUrl,
                siteLogo: props.siteLogo,
                siteInfo: props.siteInfo,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
}

export const useAppContext = () => useContext(AppContext);
