import { useLocation } from '@solidjs/router';
import clsx from 'clsx';
import { gql } from 'graphql-request';
import { Match, Show, Switch, createEffect, createSignal, onMount, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import DynamicModuleGenerator from '../../dynamic-module-generator';
import { breakpoints } from '../../style/theme';
import { ErrorCatcher } from '../../tools/error-catcher';
import { navigateToAnchor } from '../../tools/scroll-to-element';
import { NotFound } from '../404/404';
import { BlogPostIntroContainer } from '../blog-post/blog-post-intro';
import { NewsPostIntroContainer } from '../news-post/news-post-intro';
import { Schema } from '../schema/schema';
import { SeoSettings } from '../seo-settings/seo-settings';
import { PageContextProvider } from './page-context-provider';
import { removeTrailingSlash } from '../../tools/remove-trailing-slash';

export const GET_PAGE_QUERY = gql`
    query GetPage($key: String!) {
        page: resource(key: $key) {
            content
            key
        }
    }
`;

export const PageContainer = (props: any) => {
    const { createCachedResource, viewport, setStatusCode, pageTheme } = useContext(AppContext);
    const isMobile = () => viewport.width <= breakpoints.mobile;
    const location = useLocation();
    const [currentLocation, setCurrentLocation] = createSignal(location.pathname);
    const [pageContent] = createCachedResource(GET_PAGE_QUERY, () => ({ key: removeTrailingSlash(location.pathname.toLowerCase()) }), true);
    const [hasHero, setHasHero] = createSignal(pageContent()?.page?.content?.blocks[0].blockName === 'apelsin/hero');

    /**
     * TODO: Not sure this works - if there's an anchor in the url the page only sometimes smoothly scrolls down
     */
    onMount(() => {
        if (location.hash) {
            navigateToAnchor({ id: location.hash.replace('#', ''), smallScreen: isMobile() });
        }
    });

    const found = () => {
        if (pageContent.loading) {
            return true;
        }
        if (pageContent()?.page?.content) {
            return true;
        }
        setStatusCode(404);
        return false;
    };

    createEffect(() => {
        setHasHero(pageContent()?.page?.content?.blocks[0]?.blockName === 'apelsin/hero');
        if (currentLocation() !== location.pathname) {
            window.scrollTo({ top: 0 }); //, behavior: 'smooth' });
            setCurrentLocation(location.pathname);
        }
    });

    return (
        <ErrorCatcher componentName="Page">
            <PageContextProvider>
                <Show when={!found()}>
                    <NotFound />
                </Show>
                <Show when={found()}>
                    <div
                        class={clsx(
                            { 'pt-[10rem]': !hasHero() },
                            { 'desktop:pt-[16rem]': !hasHero() },
                            { 'tablet:pt-[12rem]': !hasHero() },
                            pageTheme().colors.classes,
                        )}
                    >
                        <Switch>
                            <Match when={pageContent()?.page?.content}>
                                <SeoSettings currentUrl={() => pageContent().page?.content?.permalink} seo={() => pageContent().page?.content?.seo} title={pageContent()?.page?.content?.post_title} />
                                <Show when={pageContent()?.page?.content?.type === 'blog'}>
                                    <BlogPostIntroContainer
                                        author={pageContent()?.page?.content?.author}
                                        date={pageContent()?.page?.content?.date}
                                        categories={pageContent()?.page?.content?.categories}
                                        featuredImage={pageContent()?.page?.content?.featured_image}
                                        postTitle={pageContent()?.page?.content?.post_title}
                                        preamble={pageContent()?.page?.content?.preamble}
                                        readingTime={pageContent()?.page?.content?.reading_time}
                                        postType={pageContent()?.page?.content?.post_type}
                                        backgroundColor={pageTheme()?.colors?.bgColor}
                                    />
                                </Show>
                                <Show when={pageContent()?.page?.content?.type === 'news'}>
                                    <NewsPostIntroContainer
                                        preamble={pageContent()?.page?.content?.preamble}
                                        date={pageContent()?.page?.content?.date}
                                        featuredImage={pageContent()?.page?.content?.featured_image}
                                        postTitle={pageContent()?.page?.content?.post_title}
                                    />
                                </Show>

                                <DynamicModuleGenerator
                                    content={pageContent().page?.content.blocks}
                                    categories={pageContent().page?.content.categoriesFromHook}
                                    permalink={pageContent().page?.content.permalink}
                                    pageTitle={pageContent().page?.content.post_title}
                                    postType={props.postType}
                                />
                            </Match>
                        </Switch>
                    </div>

                    <Schema content={pageContent()?.page?.content} />

                    {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                </Show>
            </PageContextProvider>
        </ErrorCatcher>
    );
};
